import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { get_data, get_data_post, get_user, paginationService } from "./api";
import axiosInstance from "./httpInstance";

/*
 * Type definitions
 */
interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;

  // Records per page to be shown & loaded
  recordsPerPage: number;

  // Response from API
  responseData: any;

  // Is data being load from API, to show loader in parent component
  isLoadingData: any;

  // Reload the API, will be triggered from parent
  reloadApi: boolean;

  // Search searchText from parent component
  search?: any;
  filter_data?: any;
  fatherkey?: any;
  // Is searching data when search is triggered
  isSearchingData: any;
  recipient_id?:number;
  page_number?:number;
  
}

/*
 * Pagination7 with props
 */
export const Pagination7 = ({
  apiRoute,
  recordsPerPage = 10,
  responseData,
  isLoadingData,
  reloadApi,
  search,
  filter_data,
  isSearchingData,
  fatherkey,
  recipient_id,
  page_number,
}: Props) => {
  // Hooks
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pager, setPager] = useState({} as any);
  const [totalRecordsPage, setTotalRecordsPage] = useState(0);
  const currentPageRef = useRef(1);

  /*
   * Fetch data from API
   * Append query params if any
   * API call with GET
   */
  const getData = (pageNumber: number) => {
    isLoadingData(true);
    let new_data={
      pageNumber:1,
      page:1,
      recordsPerPage:recordsPerPage,
      filter:filter_data
      ,searchTerm:search.searchText
      ,search:search.searchText
      ,father_key:fatherkey
      ,recipient_id:recipient_id
    }
    if(page_number==0)
    {
      setCurrentPage(Number(1));
      
    let finalApiRoute = `${apiRoute}
    `;
    get_data_post(finalApiRoute,new_data)
      .then((response: any) => {
        isLoadingData(false);
        isSearchingData(false);
        
        setTotalCount(response.data.count);
        responseData(response.data.list);
        setTotalRecordsPage(Math.ceil(response.data.count / recordsPerPage));
        setPagination7(response.data.count, pageNumber, recordsPerPage);
      })
      .catch((error) => {
        isLoadingData(false);
        isSearchingData(false);
      });
    }else
    {
      setCurrentPage(Number(pageNumber));
      new_data.pageNumber=pageNumber;
      new_data.page=pageNumber;
      
      let finalApiRoute = `${apiRoute}`;
      get_data_post(finalApiRoute,new_data)
        .then((response: any) => {
          isLoadingData(false);
          isSearchingData(false);
          
          setTotalCount(response.data.count);
          responseData(response.data.list);
          setTotalRecordsPage(Math.ceil(response.data.count / recordsPerPage));
          setPagination7(response.data.count, pageNumber, recordsPerPage);
        })
        .catch((error) => {
          isLoadingData(false);
          isSearchingData(false);
        });
    }


  };

  /*
   * Set Pagination7 data and pager data
   */
  const setPagination7 = (
    totalCount: number,
    pageNumber: number,
    recordsPerPage: number
  ) => {
    const pData = paginationService.getPager(
      totalCount,
      pageNumber,
      recordsPerPage
    );
    setPager({ ...pData });
  };

  /*
   * Watch reloadApi flag
   */
  useEffect(() => {
    if (reloadApi) {
      getData(currentPage);
    }
  }, [reloadApi]);

  /*
   * Component initiated
   */
  useEffect(() => {
    getData(currentPage);
  }, []);

  /*
   * Watch current page
   */
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  /*
   * Watch search
   */
  useEffect(() => {
    if (search && search.searchText) {
      isSearchingData(true);
      getData(currentPage);
    }
  }, [search]);

  /*
   * Watch recordsPerPage
   */
  useEffect(() => {
    getData(currentPage);
  }, [recordsPerPage]);

  /*
   * Render
   */
  return (
    <div>
      
      {totalCount > 0 && (
        <div className="d-flex flex-stack flex-wrap pt-10">
              
    
          <div className="fs-6 fw-bold text-gray-700">
            Showing {pager.startIndex + 1} to {pager.endIndex + 1} of{" "}
            {totalCount} records
          </div>
          {/* <nav className="pages"> */}
            <ul className="pagination">
              <li
                className={
                  currentPage === 1 ? "disabled page-item" : "page-item"
                }
              >
                <a
                  href="#!"
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    getData(currentPageRef.current - 1);
                  }}
                >
                  Previous
                </a>
              </li>
              {pager.pages &&
                pager.pages.map((page: number, index: number) => {
                  return (
                    <li
                      key={index}
                      className={
                        currentPage === page
                          ? "custom-disabled active page-item"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          getData(page);
                        }}
                      >
                        {page}
                      </a>
                    </li>
                  );
                })}
              <li
                className={
                  currentPage + 1 > totalRecordsPage
                    ? "disabled page-item"
                    : "page-item"
                }
              >
                <a
                  className="page-link"
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    getData(currentPageRef.current + 1);
                  }}
                >
                  Next
                </a>
              </li>
            </ul>
          {/* </nav> */}
        </div>
   
        



      )}
    </div>
  );
};
