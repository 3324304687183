/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux' 
import {ErrorMessage, Field, FieldArray, Form,  Formik, useFormik} from 'formik'

import * as Yup from 'yup'
import clsx from 'clsx' 
import { useCallback } from 'react';

import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 

import { api_add, FileUpload, get_data, get_data_json, user_add } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { FormSelect } from '../FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from '../FileUploadServices'
import { FormTabs } from '../FormTabs' 
import { initialValues } from './Manage_bills_billsModel'
import { FormSelectSearch } from '../FormSelectSearch'

const initialValues7 = {
  companyName: '',
  contactName: '',
  contactTitle: '',
  Address: '',
  city: '',
  region: '',
  postalCode: '',
  country: '',
  phone: '',
  fax: '',
  currency_code:'',
  filed2: '',
  sam_chiled:[]
}
 
 const addManage_bills_billsSchema = Yup.object().shape({
  
 
 
})

export function AddManage_bills_bills() {
  const d=useHistory();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const params = useParams<any>();
  const [Manage_bills_bills, setManage_bills_bills] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const [showAlert, setShowAlert] = useState(false);
  const [ShowTotal, setShowTotal] = useState(false);
   
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  const [AddValueTabs, setAddValue] = useState({} as any); 
  const [AddValue, setAddValueArray] = useState([] as any);
  const [AddValueEdit, setAddValueEdit] = useState([]);
  

const validationSchema7 = Yup.object().shape({
     
  
});
const onSubmit7 =  (fields:any) => {
  // display form field values on success
 
}

  const toggleShowAlert = () => setShowAlert(!showAlert);
  
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      
      setManage_bills_bills(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
        
  
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);

   
  const handleLgAdd = (e: any, values: any, setValues: any)=>
  {
   // update dynamic form
    
   const tickets = [...values.tickets];
   tickets.push({p_key:0,filed1:'',filed2:'',filed3:''});
    
   setValues({ ...values, tickets });
  
 }
 
const handleLgClose = (event: any) =>
{

  if(event)
 {
   formik.setFieldValue(event.key,event.value); 

 } 


  // debugger

} 

 const handleLgAdd_array = (e: any, values: any, setValues: any)=>
  {
   // update dynamic form
    debugger
   if(values.tickets.length==0&&AddValueEdit.length>0)
   {
     
     const tickets = [...values.tickets];
     AddValueEdit.forEach(element => {
     tickets.push(element);
         
       });
   
     setValues({ ...values, tickets });
   }
 
  
 }
 
   const handleFormTabs = (event: any) =>
{

  if(event.data)
  {
    setAddValue(event)
    if(event.total)
    {
    formik.setFieldValue('total',event.total);

      setShowTotal(true)

    }

  }
  
 
} 







const Manage_bills_billsAddSchema = Yup.object().shape({
  
   
 




  bill_number: Yup.string()
    .required('bill_number is required'),  billed_at: Yup.date(),  contact_id: Yup.number(),  notes: Yup.string(),  amount: Yup.number(),  type_id: Yup.number(),
})


  const load_data_ = (fa:number) => {
  
    let ib='';
    let data_filter=''; 
    let currentPage='';
    let sam_type='';
    let skip=''; 
    let take='';
    let Url='';
    let download_by_value='';
    let searchText='';
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data('sam_role_select/sam_root_id_filed?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+searchText+'&page='+currentPage
  +'&get_states='+sam_type
  +'&='+fa
  +'&download_by_value='+download_by_value
  +'&root_id=sam_root_id_filed'
   )
    .then(data => {
     // SetValueSelect(data.data.data)
      
      
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
    
  
  }

  const [contacts_value_select, SetValueSelectcontacts] = useState([] as any);

  const load_data_contacts = (fa:number) => {
  
  



    let ib='';
    let data_filter=''; 
    let currentPage='';
    let sam_type='';
    let skip=''; 
    let take='';
    let Url='';
    let download_by_value='';
    let searchText='';
 
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data('getcontacts_select?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+searchText+'&page='+currentPage
  +'&get_states='+sam_type
  +'&='+fa
  +'&download_by_value='+download_by_value
  +'&root_id=198699'
   )
    .then(data => {
      SetValueSelectcontacts(data.data.data)
      
      
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
    
  
  } 
   const [currencies_value_select, SetValueSelectcurrencies] = useState([] as any);

  const load_data_currencies = (fa:number) => {
  
  



    let ib='';
    let data_filter=''; 
    let currentPage='';
    let sam_type='';
    let skip=''; 
    let take='';
    let Url='';
    let download_by_value='';
    let searchText='';
 
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data('getcurrencies_select?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+searchText+'&page='+currentPage
  +'&get_states='+sam_type
  +'&='+fa
  +'&download_by_value='+download_by_value
  +'&root_id=198701'
   )
    .then(data => {
      SetValueSelectcurrencies(data.data.data)
      
      
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
    
  
  } 
   const [samtypes_value_select, SetValueSelectsamtypes] = useState([] as any);

  const load_data_samtypes = (fa:number) => {
   
    let ib='';
    let data_filter=''; 
    let currentPage='';
    let sam_type='';
    let skip=''; 
    let take='';
    let Url='';
    let download_by_value='';
    let searchText='';
 
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data('getsamtypes_select?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+searchText+'&page='+currentPage
  +'&get_states='+sam_type
  +'&='+fa
  +'&download_by_value='+download_by_value
  +'&root_id=198702'
   )
    .then(data => {
      SetValueSelectsamtypes(data.data.data)
      
      
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
    
  
  }

  
   const AddedDate=new Date().valueOf(); 
  const formik = useFormik({
    initialValues,
    validationSchema: Manage_bills_billsAddSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      
      setTimeout(() => {
         values.sam_chiled=[];
          
         values.AddedDate=AddedDate;
         values.fatherkey=params.fatherkey;
         values.data_object=AddValueTabs;
         values.recipient_id=null;

        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          fatherkey:params.fatherkey,
          data_object:AddValueTabs, 
          recipient_id:null
        } 

        api_add('manage_bills_bills', values)
          .then((data:any) => {
            // 
            if(data.data.status)
            {
             
              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
            d.goBack()

            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)

            }
            setLoading(false) 
            // d.goBack()

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            // d.goBack()
            
            setSubmitting(false)
            setStatus('AddManage_bills_bills process has broken')
            setStatus_code('danger')
            setStatus_massag('AddManage_bills_bills process has broken')
          })
      }, 1000)
    },
  })

  // const handleKeyPress = useCallback((event) => {
  //   console.log(`Key pressed: ${event.key}`);
  //   if(event.key=='F9')
  //   {
  //     debugger
  //     alert('Woooooooooooooo') ; 
  //   } 
  // }, []);

  // useEffect(() => {
  //   // attach the event listener
  //   document.addEventListener('keydown', handleKeyPress);

  //   // remove the event listener
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [handleKeyPress]);

  // const onKeyPress = (event) => {
  //   console.log(`key pressed: ${event.key}`);
  // };

  // useKeyPress(['a', 'b', 'c'], onKeyPress);



  return (
   
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>   المشتريات </span> 
         
       
          <span className='card-label fw-bolder fs-3 mb-1'>    اضافة جديد </span> 
 
        </h3>
        <div className='card-toolbar'>
       
          <Link to={"/crafted/Manage_bills_bills/dashboard/manage_bills_bills"} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </Link>
          {/* <Link to="add">new </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table'>
       
        {showAlert && (
          
  <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
  <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
  <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
    <h5 className="mb-1">رسالة</h5>
    <span>{status_massage}.</span>
  </div>

  <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
    <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
  </button>
</div>



        )}
     



          {/* begin::Table */}
          <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
 
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
  







    <div className='row fv-row mb-7'>
  

 
        <div   className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> رقم الفاتورة</label>
      <input
        placeholder='رقم الفاتورة'
        type='text' 
        autoComplete='off'
            {...formik.getFieldProps('bill_number')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.bill_number && formik.errors.bill_number,
              },
              {
                'is-valid': formik.touched.bill_number && !formik.errors.bill_number,
              }
            )}
          />
      
          {formik.touched.bill_number && formik.errors.bill_number && (

            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>      <div className='col-xl-4'>
          <label className='class="form-label fw-bolder text-dark fs-6'>التاريخ</label>
          <input
            placeholder='التاريخ'
            type='date'
            autoComplete='off'
            {...formik.getFieldProps('billed_at')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.billed_at && formik.errors.billed_at,
              },
              {
                'is-valid': formik.touched.billed_at && !formik.errors.billed_at,
              }
            )}
          />
          {formik.touched.billed_at && formik.errors.billed_at && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.billed_at}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-4'>
          <label className='class="form-label fw-bolder text-dark fs-6'>المورد</label>
          <select
            placeholder='المورد'
        
            {...formik.getFieldProps('contact_id')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.contact_id && formik.errors.contact_id,
              },
              {
                'is-valid': formik.touched.contact_id && !formik.errors.contact_id,
              }
            )}
            >
         
                 <option value="0">اختيار القيمة</option>
 
          
          {contacts_value_select && contacts_value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

        </select>
          {formik.touched.contact_id && formik.errors.contact_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.contact_id}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-4'>
          <label className='class="form-label fw-bolder text-dark fs-6'>ملاحظات</label>
          <textarea
            placeholder='ملاحظات' 
            autoComplete='off'
            {...formik.getFieldProps('notes')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.notes && formik.errors.notes,
              },
              {
                'is-valid': formik.touched.notes && !formik.errors.notes,
              }
            )}
          >
                </textarea>

          {formik.touched.notes && formik.errors.notes && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.notes}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-4'>
          <label className='class="form-label fw-bolder text-dark fs-6'>المبلغ</label>
          <input
            placeholder='المبلغ'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('amount')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.amount && formik.errors.amount,
              },
              {
                'is-valid': formik.touched.amount && !formik.errors.amount,
              }
            )}
          />
          {formik.touched.amount && formik.errors.amount && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.amount}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-4'>
          <label className='class="form-label fw-bolder text-dark fs-6'>النوع</label>
          <select
            placeholder='النوع'
        
            {...formik.getFieldProps('type_id')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.type_id && formik.errors.type_id,
              },
              {
                'is-valid': formik.touched.type_id && !formik.errors.type_id,
              }
            )}
            >
         
                 <option value="0">اختيار القيمة</option>
 
          
          {samtypes_value_select && samtypes_value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

        </select>
          {formik.touched.type_id && formik.errors.type_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.type_id}</span>
              </div>
            </div>
          )}
        </div>      </div>
      {/* end::Form group */}
<div className='card-body py-6'>

      <div className='table-responsive'>

 

  {/* begin::Table */}
 
<Formik 

initialValues={initialValues}  validationSchema={validationSchema7} onSubmit={onSubmit7}>
  {({ errors, values, touched, setValues }) => (
    
      <Form>
          
     
  <a   className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
  onClick={e => handleLgAdd_array(e, values, setValues)} 
  >
      <KTSVG path='/media/icons/duotune/art/art009.svg' className='svg-icon-3' />

        </a>

    {/* {AddValueEdit && (
          values.tickets=AddValueEdit
        )} */}
          <div className="card m-3" >
              <h5 className="card-header">sam    </h5>
             
              <FieldArray name="tickets"
              
              >
              {() => (values.tickets.map((ticket, i) => {
                  const ticketErrors = errors.tickets?.length && errors.tickets[i]   ;
                  const ticketTouched = touched.tickets?.length && touched.tickets[i]  ;
                  return (
                      <div key={i} className="list-group list-group-flush">
 
    <div className='row fv-row mb-7'> 






<FormSelectSearch key={0} 
    apiRoute={`items`}
    parentname={`items`}
    parentvalue={`id`}
    parentlabel={`name`}
    description={``}
    descriptionen={``}
    root_id={`1`} 
  response7Data={handleLgClose}
       id={194603} 
       filed_name={'item_id'}
       data_index={8} 
       formik={formik}
       ticket={ticket}
       /> 
  
  




  <div   className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> الكمية</label>
      <input 
        placeholder='quantity'
        type='number'  
        required={true}  
        {...formik.getFieldProps(`tickets.${i}.quantity`)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur 
      }}
      />
 
    </div>
      <div   className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> السعر</label>
      <input 
        placeholder='price'
        type='number'  
        required={true}  
        {...formik.getFieldProps(`tickets.${i}.price`)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur 
      }}
      />
 
    </div>
      <div   className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> total</label>
      <input 
        placeholder='total'
        type='number'  
        required={true}  
        {...formik.getFieldProps(`tickets.${i}.total`)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur 
      }}
      />
 
    </div>
    

  
</div>


      {/* end::Form group */}
 
    
    
  
 
 
                      
                      </div>
                  );
              }))}
              </FieldArray>
              <div className="buttons">
  <button type="button" 
  className="btn btn-success mr-1"
  onClick={e => handleLgAdd(e, values, setValues)} 
  >

    اضافة جديد
  </button>

</div>
           
          </div>
        
          
      </Form>
  )}
     
</Formik>
 
  {/* end::Table */}
  {/* {paginationBasic} */}


</div>
</div>
   
    
    

      {/* end::Form group */}
  

 
      {/* end::Form group */}
 
 
      {/* <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid }
          // disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>حفظ</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
     
      </div> */}
      <div className='text-right' style={{float:"left"}}>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
          <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
            

            >
              الغاء
            </button>
        </div>
      {/* end::Form group */}
    </form>
      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}

