/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Modal, Row, Toast ,Alert} from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { delete_api, get_data, get_data_json } from '../api'  
import { Pagination7 } from '../pagination7'
// import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import clsx from 'clsx' 
import { Edit } from '../Edit'
import { useParams,useHistory} from 'react-router-dom' 
import { Accpet } from '../Accpet'
import { ListsInfo } from '../info_list'
import { ObjectInfo } from '../info'
import { Filter } from '../Filter'
import { TransServices } from '../trans/trans_services'

 
 
type Props = {
  className: string
}

const loginSchema = Yup.object().shape({
 

})

const initialValues = {
  searchText: '',
}
const INITIAL_STATE = [{ 
  id: 0,
  username: '',
  password: '',
  email: '',
  firstname: '',
  lastname: ''
}];


const ListReport: React.FC<Props> = ({className}) => {

  const [type_id, setType] = useState(true); 
  const params = useParams<any>();
  const [Data, setData] = useState([] as any);
  const [isLoadingData, setLoadingData] = useState(false);
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const d=useHistory();
   
   const [recordsPerPage, setRecordsPerPage] = useState(10); 
   const [isSearchingData, setSearchingData] = useState(false);
   const [reload, setReload] = useState(false);
   const [search, setSearch] = useState({} as any);
   const [data_ob, setDataOb] = useState({} as any);
   const [TransInfo, setDataTransInfo] = useState({} as any);
   
   const [Surveys, setSurveys] = useState({} as any);
   const [dataList, setList] = useState([] as any);
   const [dataListView, setListView] = useState([] as any);
   const [dataListInfo, setListInfo] = useState([] as any);
   
   const [type_accpet, setType_accpet] = useState('accpet');

  const load_data = () => {
    
    setData([])
    setSurveys({}) ;

    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
    // get_data('sam_role_view/'+params.type )
    get_data_json('data_'+params.type )

    .then(data => {
     setSurveys(data.data.data) ;
     setList(data.data.list) ;
     let d=data.data.list;
       
     if(d.length>0)
     {
     let data_list_view = d.slice(0,9); 
     setListView(data_list_view)
     }
     
      setLoading(false)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })
  };


  // const { slice, range } = useTable(data, page, rowsPerPage);
  if(params.type )
  {
    if(params.type!=type_id)
    {
    setType(params.type)

    load_data()
    console.log('useEffect 2');

    }
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // setLoading(false)
      setSearch(values)

      setTimeout(() => {
         
      
      }, 1000)
    },
  })
  

 
  const [loading, setLoading] = useState(false)

 
  const [showA, setShowA] = useState(true); 
  const [showFilter, setShowFilter] = useState(false); 
 
  const [filter_data, setFilter_data] = useState(''); 


  const toggleShowA = () => setShowA(!showA);
  const toggleShowFilter = () => setShowFilter(!showFilter);
  
  const [lgShow, setLgShow] = useState(false);
  const [isAccpetShow, setAccpetShow] = useState(false);
  const [isTransShow, setTransShow] = useState(false);
  const handleTransShow= () =>{
    setTransShow(false);
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  } 
  const handleAccpetShow= () =>{
    setAccpetShow(false);
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  } 

  const [isInfoShow, setInfoShow] = useState(false);
 
  const handleInfoShow= () => setInfoShow(false);

  const [isInfoListShow, setInfoListShow] = useState(false);
 
  const handleInfoListShow= () => setInfoListShow(false);


 const [show, setShow] = useState(false);

 const handleLgClose = () =>
 {
  setLgShow(false);
  setReload(true);
  setTimeout(() => {
    setReload(false);
  }, 1000);
 } 

  // const { register, handleSubmit, errors } = useForm();
  
 
  /*
   * Clear search results
   * Make search object empty if no value
   * Trigger reload in pagination
   */
  const clearSearchResults = (event: any) => {
    if (!event.target.value && search) {
      setSearch({
        term: "",
      });
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log('useEffect ');
    // if(params.type )
    // {
    //   load_data();

    // }
 
   }, []);
 


  const delete_data = (event: any,id:number) => {
    
   
    handleClose()
    delete_api('Data/'+id)
    .then(data => {
      
       
 
      setLoading(false)
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 1000);
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })
  };
  
  
  const RespFilter_data = (e:any) => {
    
    setFilter_data(e)
    toggleShowFilter()
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  };
  const delete_data_info = (event: any,value:any) => {
    
 
    setShow(true);
    setDataOb(value)
  };
  const edit_data_info = (event: any,value:any) => {
    
    setDataOb(value)
    setLgShow(true);

  };
  const accpet_data_info = (event: any,value:any,type:any) => {
    
    setType_accpet(type)
    setDataOb(value)
    setAccpetShow(true); 
  
  };
  
  const object_data_info = (event: any,value:any,type:any) => {
    
    setDataOb(value)
    // setAccpetShow(true);
    setInfoShow(true);
  
  };
  const list_data_info = (event: any,value:any,type:any) => {
    
    setDataOb(value)

    get_data('getAllsub_activities/'+value.trans_key )
    .then(data => { 
      debugger
     setListInfo(data.data.data) ;
  
      setInfoListShow(true);
 
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => { 
   
    })
    // setAccpetShow(true);
  
  };
  const get_data_excel = (event: any,value:any,type:any) => {
    
    setDataOb(value)

    let finalApiRoute = `get_data_excel?pageNumber=1&page=1&recordsPerPage=${recordsPerPage}&filter=${filter_data}
    &searchTerm=${search.searchText || ""}&search=${search.searchText || ""}
 
    `;

    get_data(finalApiRoute)
    .then(data => { 
      debugger
      
    //  setListInfo(data.data.data) ;
  
      // setInfoListShow(true);
 
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => { 
   
    })
    // setAccpetShow(true);
  
  };
  
  const trans_data_info = (event: any,value:any,TransInfo:any) => {
    
    setDataOb(value)
    setDataTransInfo(TransInfo)
    

    setTransShow(true);
     
    // setAccpetShow(true);
  
  };

  

  return (
    
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  
          {Surveys.title_root}
          
          </span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>

        <div className='card-toolbar'>
             <a className='btn btn-sm btn-light-primary'
onClick={toggleShowFilter}
>
filter

<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
 </a>
 {Surveys.root_id==211383 && (
 <>
  <a className='btn btn-sm btn-light w-50 mb-5'
 onClick={(e) => get_data_excel(e,Surveys,'excel')}

>excel </a>

 <a
        href="http://127.0.0.1:8000/json_file/csvFile.csv"
        target="_blank"
        rel="noreferrer"
      >
        open
      </a>
      </>

 )}
 
 
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
               
          {/* <Row>
      <Col md={6} className="mb-2">
        <Button onClick={toggleShowA} className="mb-2">
          Toggle Toast <strong>with</strong> Animation
        </Button>
        <Toast show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </Col>
      </Row> */}
  
  <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="flex-grow-1 filter-container-c">
          <div className="search">
            {/* <Form onSubmit={handleSubmit(setSearch)}> */}
            <form
      className='form w-50'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    > 
      {/* begin::Heading */}
      {/* begin::Form group */} 
      <Row>
                <Col md={6} lg={6}>
                  <InputGroup>
                  <div className='fv-row mb-2'>
 
        <input
          placeholder='searchText'
          {...formik.getFieldProps('searchText')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.searchText && formik.errors.searchText},
            {
              'is-valid': formik.touched.searchText && !formik.errors.searchText,
            }
          )}
          type='text'
          name='searchText'
          autoComplete='off'
        />
        {formik.touched.searchText && formik.errors.searchText && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.searchText}</span>
          </div>
        )}
      </div>
                    <span>
                      <i className="bx bx-fw bx-search"></i>
                    </span>
                    <InputGroup>
                    <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-primary w-50 mb-5'
          disabled={isSearchingData || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>بحث</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
       
 

        <a className='btn btn-sm btn-light w-50 mb-5'
                     
                        onClick={toggleShowFilter}
                      >filter</a>
       
    
                    </InputGroup>
                  </InputGroup>
                </Col>
                <Col md={6} lg={6}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Form.Group>
                      <label className="pr-3">عدد الصفوف</label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        as="select" 
                        name="recordsPerPage"
                        defaultValue={10}
                        onChange={(e) =>
                          setRecordsPerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="">Select value</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
      {/* end::Form group */}

     
      {/* end::Form group */}

 
      {/* end::Action */}
    </form>

            
          </div>
        </div>
      </div>
      <div >
          
          {showFilter && (
        
        <Filter
       
    apiRoute=''
    dataObject={Surveys}
    responseData={RespFilter_data}
    dataList={Surveys?.sam_search}
    dataList2={dataListView}
     ></Filter>
 
      )}
   
          </div>
   
      <Row>
        <Col md={12} lg={12}>
       
          <Card>
            <Card.Body className="pt-0">
              {/* <Table striped hover>
                <thead>
                  <tr>
                    <th className="bt-none">Name</th>
                    <th className="bt-none">Email</th>
                    <th className="bt-none">Department</th>
                  </tr>
                </thead>   
              </Table> */}
 
              <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>

              
              {dataListView && dataListView.map((plan:any, index:number) => {
                      return (
                        <th className='min-w-125px text-center' key={index}>{plan.title}</th>

                        );
                      })} 
                {/* <th className='min-w-120px'>Progress</th> */}
                
           
                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
         

            {!Data.length && !isLoadingData && (
                    <tr className="text-center">
                      <td colSpan={3}>No Data</td>
                    </tr>
                  )}
                  {isLoadingData && (
                    <tr className="text-center">
                      <td colSpan={3}>
                        <span>Loading...</span>
                      </td>
                    </tr>
                  )}
  
                      {!isLoadingData &&Data.map((item:any, index:number) => {
                      return (
                        <tr
                        key={index}
                        >
                              {dataListView && dataListView.map((zitem:any, index:number) => {
                      return (
                        
                        <td key={index}>
                           {zitem.ztype=='file' && (
                      <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                      {item[zitem?.status_type]}
                      file
                      </span>
                  )}

                  
{zitem.titleen=='status_id' && item[zitem?.status_type]=='ناجح' && (
                  <span className={`badge badge-light-success  fs-7 fw-bold d-block mb-1 fs-6`}>  {item[zitem?.status_type]}</span>
               
                  )}
{zitem.titleen=='status_id' && item[zitem?.status_type]=='راسب' && (
                  <span className={`badge badge-light-danger fs-7 fw-bold d-block mb-1 fs-6`}>  {item[zitem?.status_type]}</span>
               
                  )}


                            {zitem.ztype!='file' && zitem.titleen!='status_id' &&(

                            
                      <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                      {item[zitem?.status_type]}
                      </span>
                  )}
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
                      </td>  
                        );
                      })} 
                 
                 {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>{item?.trans_level}%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{width: `${item.trans_level}%`}}
                      ></div>
                    </div>
                  </div>
                </td>    */}
                 
               

                        </tr>
                      )
                    })} 

           
 

             
            
             
            </tbody>
            {/* end::Table body */}
          </table>

              <Pagination7
                apiRoute={`sam_role_list/`+type_id}
                recordsPerPage={recordsPerPage}
                responseData={setData}
                isLoadingData={setLoadingData}
                reloadApi={reload}
                search={search}
                filter_data={filter_data}
                fatherkey={params.father_key}
                
                isSearchingData={setSearchingData}
            
              /> 
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>

      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>

  
 
      
          </div>
  

      </div>
      {/* begin::Body */}

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton className='btn btn-sm btn-danger'>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body> */}

        <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
  <button type="button" className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger" data-bs-dismiss="alert">
    <span className="svg-icon svg-icon-1">...</span>
  </button>

  <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>

  <div className="text-center">
    <h5 className="fw-bolder fs-1 mb-5">This is an alert</h5>

    <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

    <div className="mb-9">
      The alert component can be used to highlight certain parts of your page for <strong>higher content visibility</strong>.<br/>
      Please read our <a href="#" className="fw-bolder me-1">Terms and Conditions</a> for more info.
    </div>
    <div className="d-flex flex-center flex-wrap">
      <Button className="btn btn-outline btn-outline-danger btn-active-danger m-2" onClick={handleClose}>Cancel</Button>
      <Button className="btn btn-danger m-2"
           onClick={(e) => delete_data(e,data_ob.p_key)}>Ok, I got it</Button>
    </div>
  </div>





</div>

        {/* <p>هل انت متاكد من حذف
        <br>
        </br>
          {data_ob.p_key}
          {data_ob.name}
        </p> */}
     
        {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            الغاء
          </Button>
          <Button variant="primary" 
              onClick={(e) => delete_data(e,data_ob.p_key)}
              >
            نعم
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal
      key={1}

        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.p_key} 
        <br>
        </br>
          {data_ob.name}

        <Edit apiRoute={`sam`} id={data_ob.p_key} 
                responseData={handleLgClose}
        
        />

        </Modal.Body>
      </Modal>
      
  
      <Modal
      key={2}
        size="lg"
        show={isAccpetShow}
        onHide={() => setAccpetShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Accpet Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.p_key} 
        <br>
        </br>
          {data_ob.name}

        <Accpet apiRoute={`sam`} id={data_ob.p_key} 
          trans_key={data_ob.trans_key} 
                respData={handleAccpetShow}
                type_accpet={type_accpet} 
        
        />

        </Modal.Body>
      </Modal>

      <Modal
      key={2}
        size="lg"
        show={isTransShow}
        onHide={() => setTransShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Accpet Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.p_key} 
        <br>
        </br>
          {data_ob.name}

        
            
      <TransServices
     panelName={TransInfo.action_type}
     data_ob={data_ob}
     Surveys={TransInfo}
     RespFilter_data={handleTransShow}>
       
     </TransServices>

        </Modal.Body>
      </Modal>
  
      <Modal
      key={2}
        size="lg"
        show={isInfoShow}
        onHide={() => setInfoShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Accpet Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.p_key} 
        <br>
        </br>
          {data_ob.name}

          <ObjectInfo className='card-xxl-stretch' data_object={data_ob} dataList={dataList}
          respData={setInfoShow} />
       

        </Modal.Body>
      </Modal>
      
      <Modal
      key={2}
        size="lg"
        show={isInfoListShow}
        onHide={() => handleInfoListShow()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Accpet Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.p_key} 
        <br>
        </br>
          {data_ob.name}

          <ListsInfo  className='card-xxl-stretch' data={dataListInfo}
            respData={handleInfoListShow}
            />
       

        </Modal.Body>
      </Modal>
    </div>
    
  )
}

export {ListReport}
function Surveys_model(Surveys_model: any): [any, any] {
  throw new Error('Function not implemented.')
}

