import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { AddSurveys } from './AddSurveys' 
import { AddTestSurveys } from './AddTestSurveys'
import { ListCurrencies } from './currencies/Currencies'
import { AddCurrencies } from './currencies/CurrenciesAdd'
// import { CurrenciesAdd2 } from './currencies/CurrenciesAdd2'
import { EditReturn } from './EditReturn'
import { EditSurveys } from './EditSurveys'
import { ListGrievances } from './grievances/Grievances'
import { AddGrievances } from './grievances/GrievancesAdd'
import { InfoSurveys } from './InfoSurveys'
import { ListAccountsTrans } from './ListAccountsTrans'
import { ListContectTrans } from './ListContectTrans'

import { ListSurveys } from './ListSurveys'
import { ListSurveysSub } from './ListSurveysSub'
import { ListManage_bills_bills } from './manage_bills_bills/Manage_bills_bills'
import { AddManage_bills_bills } from './manage_bills_bills/Manage_bills_billsAdd'
import { Manage_bills_billsEdit } from './manage_bills_bills/Manage_bills_billsEdit'

import { AddSeries } from './series/AddSeries'
import { EditSeries } from './series/EditSeries'
import { ListFinger } from './series/ListFinger'
import { ListReport } from './series/ListReport'
import { ListReport7 } from './series/ListReport7'
import { ListSeries } from './series/ListSeries'
import { ListSeriesReport } from './series/ListSeriesReport'
import { ListSeriesShare } from './series/ListSeriesShare'
import { SurveyList } from './trans/SurveyList'

 

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: 'surveys',
    path: '/crafted/surveys/index',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]      
const SurveysPage: React.FC = () => {
  return (
    <Switch>
     
    
    
      <Route path='/crafted/surveys/index'>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>قائمة البيانات </PageTitle>
        <ListSurveys className='mb-5 mb-xl-8' />
    
      </Route>

      <Route path='/crafted/surveys/add'>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>wesam add </PageTitle>
        <AddSurveys   />
      </Route>
  
       {/* ///father/ */}
      <Route exact path='/crafted/surveys/dashboard/:type/chiled/:father_key' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>قائمة البيانات </PageTitle>
    <ListSurveys className='mb-5 mb-xl-8' />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/chiled/:father_key/add'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>اضافة جديد </PageTitle>
    <AddSurveys   />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/chiled/:father_key/edit/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>تعديل البيانات </PageTitle>
    <EditSurveys   />

  </Route>
  

{/* end father */}


  {/* ///father/ */}
  <Route exact path='/crafted/surveys/dashboard/:type/list/:subid' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>قائمة البيانات </PageTitle>
    <SurveyList className='mb-5 mb-xl-8' />

  </Route>
  <Route exact path='/crafted/surveys/dashboard/:type/list/:subid/show/:survey_id' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>قائمة البيانات </PageTitle>
    <ListSurveysSub className='mb-5 mb-xl-8' />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/list/:subid/show/:survey_id/add'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>اضافة جديد </PageTitle>
    <AddSurveys   />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/list/:subid/show/:survey_id/edit/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>تعديل البيانات </PageTitle>
    <EditSurveys   />

  </Route>
  

{/* end father */}

      <Route path="/crafted/surveys/edit/:id" component={EditSurveys} />
          
      <Route path='/crafted/surveys/dashboard/:type/add_test'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>اضافة جديد </PageTitle>
    <AddTestSurveys   />

  </Route>

      <Route path='/crafted/surveys/dashboard/:type/add'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>اضافة جديد </PageTitle>
    <AddSurveys   />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/edit/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>تعديل البيانات </PageTitle>
    <EditSurveys   />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/return/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>مرتجع  </PageTitle>
    <EditReturn   />

  </Route>
  
  <Route path='/crafted/surveys/dashboard/:type/info/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}> التفاصيل </PageTitle>
    <InfoSurveys   />

  </Route>

  
  <Route path='/crafted/surveys/dashboard/:type/list_trans/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}> كشف الحساب </PageTitle>
    <ListContectTrans   />

  </Route>
  <Route path='/crafted/surveys/dashboard/:type/list_accounts_trans/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}> كشف الحساب </PageTitle>
    <ListAccountsTrans   />

  </Route>
  
      <Route exact path='/crafted/surveys/sam/:type' component={ListSurveys} />
  
      <Route exact path='/crafted/surveys/dashboard/:type' >
    
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>قائمة البيانات </PageTitle>
        <ListSurveys className='mb-5 mb-xl-8' />
    
      </Route>

      <Route path='/crafted/surveys/series/:type/add'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>Series add </PageTitle>
    <AddSeries   />

  </Route>

  <Route path='/crafted/surveys/series/:type/edit/:id'>
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>Series edit </PageTitle>
    <EditSeries   />

  </Route>
  
  <Route exact path='/crafted/surveys/series_share/:type' >
  
    <PageTitle breadcrumbs={widgetsBreadCrumbs}> share </PageTitle>
    <ListSeriesShare className='mb-5 mb-xl-8' />

  </Route>
  <Route exact path='/crafted/surveys/series_report/:type' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}> report </PageTitle>
    <ListSeriesReport className='mb-5 mb-xl-8' />

  </Route>

      <Route exact path='/crafted/surveys/series/:type' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}> Series </PageTitle>
    <ListSeries className='mb-5 mb-xl-8' />

  </Route>

  <Route exact path='/crafted/surveys/report/:type' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>  report </PageTitle>
    <ListReport className='mb-5 mb-xl-8' />

  </Route> 
  <Route exact path='/crafted/surveys/tree/:type' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>  tree </PageTitle>
    <ListReport7   />

  </Route>

  <Route exact path='/crafted/surveys/listFinger/:type' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>  listFinger </PageTitle>
    <ListFinger className='mb-5 mb-xl-8' />

  </Route>
  
      

      {/* <Route path='/crafted/surveys/currencies_add2'>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>wesam add </PageTitle>
        <CurrenciesAdd2   />
      </Route> */}
  


  <Route exact path='/crafted/surveys/currencies' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>  currencies </PageTitle>
    <ListCurrencies className='mb-5 mb-xl-8'  />

  </Route>


      <Route path='/crafted/surveys/currencies/add'>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>wesam add </PageTitle>
        <AddCurrencies   />
      </Route>


      {/* gggg */}
      
      
      
  <Route exact path='/crafted/surveys/grievances' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>  grievances </PageTitle>
    <ListGrievances className='mb-5 mb-xl-8'  />

  </Route>


      <Route path='/crafted/surveys/grievances/add'>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>grievances add </PageTitle>
        <AddGrievances   />
      </Route>
      {/* end ggg */}



      
  <Route exact path='/crafted/surveys/manage_bills_bills' >
    
    <PageTitle breadcrumbs={widgetsBreadCrumbs}>  manage_bills </PageTitle>
    <ListManage_bills_bills className='mb-5 mb-xl-8'  />

  </Route>


      <Route path='/crafted/surveys/manage_bills_bills/add'>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>bills add </PageTitle>
        <AddManage_bills_bills   />
      </Route>

   

      <Route path="/crafted/surveys/edit/:id" component={EditSurveys} />
      
      <Redirect from='/crafted/surveys' exact={true} to='/crafted/surveys/index' />
      <Redirect to='/crafted/surveys/index' />
    </Switch>
  )
}

export default SurveysPage
