export interface Manage_bills_bills_model {






  bill_number: string
  contact_id: number
  amount: string
  currency_id: number
  type_id: number
  notes: string
}




export let initialValues = {
  sam_chiled:[],
  tickets:[],
  AddedDate:0,
fatherkey:null,
data_object:null, 
  order_by:'',
  quantity:0,
  order_how:'',
recipient_id:null,
  





  bill_number: '', 
  billed_at: null, 
  contact_id: 0, 
  notes: '', 
  amount: null, 
  type_id: 0, 
}

