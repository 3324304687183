/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { initialValues } from './Manage_bills_billsModel'
 
 
let initialValues7 = {
  title: '',
  name: '',
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',


}

const FilterSchema = Yup.object().shape({
 
 
})

interface Props { 
  apiRoute: string;
  dataObject: any;
  responseData:any;
  dataList:any;
  dataList2?:any;

}
export interface IPanelProps {
  txt?: string;
  data_ob: any;
  formik: any;
  data_index: number;
  
  value_object: any;
}

export const Manage_bills_billsFilter = ({
  apiRoute,
  responseData,
  dataObject,
  dataList,
  dataList2,

}: Props) => {
// class Filter extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [data_order_list, Setdata_order_list] = useState([] as any)
  const [data_order_list1, Setdata_order_list1] = useState([] as any)

 
 
  const dispatch = useDispatch()
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: FilterSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
         
        responseData(JSON.stringify(values));
      }, 1000)
    },
  });

  
  useEffect(() => {
     
    const d1=data_order_list1;
    for  ( const [key, value] of Object.entries(initialValues))
    {
      // console.log(key)
      // console.log(key)
      d1.push({id:key,label:value})
    }
    setTimeout(() => {
      let s=d1;
      if(data_order_list.length<1)
           Setdata_order_list(s)
      
    }, 100);
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>filter 
            {/* { params.id } */}
         
            </span>
            <br/>
            {/* { params.id } */}
 
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
         
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
   


    <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
 
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
  
     
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
  
        {/* begin::Form group Firstname */}
      
        {/* <div className='row fv-row mb-3'> */}
        <div className='row fv-row mb-7'>
 
        {/* <Row> */}





          <div className='col-xl-4'>
            <label className='class="form-label fw-bolder text-dark fs-6'>رقم الطلب</label>
            <input
              placeholder='رقم الطلب'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('order_number')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
              
              )}
            />
         
          </div>
           
          <div className='col-xl-4'>
            <label className='class="form-label fw-bolder text-dark fs-6'>رقم الفاتورة</label>
            <input
              placeholder='رقم الفاتورة'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('bill_number')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
              
              )}
            />
         
          </div>
           
    <div   className='col-xl-4'  >
    
    <label className='class="form-label fw-bolder text-dark fs-6'> الترتيب بحسب</label>
 
 <select
        placeholder='order_by'  
        {...formik.getFieldProps('order_by')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      >
        
          
        <option value="0">اختيار القيمة</option>
        
          
          {dataList2 && dataList2.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.titleen} >{plan.title}</option>

                        );
                      })} 

 
        </select>
      
    </div>

    <div   className='col-xl-4'  >
    
    <label className='class="form-label fw-bolder text-dark fs-6'> الترتيب تصاعدي</label>
 
 <select
        placeholder='order_how'  
        {...formik.getFieldProps('order_how')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      >
        
           
        <option value="ASC">تنازلي </option>
        <option value="DESC"> تصاعدي </option> 
 
        </select>
      
    </div>
    

    </div>
  
{/* </div> */}
        {/* end::Form group */}
   
      
        {/* end::Form group */}
  
        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid  }
          >
            {!loading && <span className='indicator-label'>بحث</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
       
        </div>
        {/* end::Form group */}
      </form>
        







    
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default Filter;
