/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { get_data } from '../../../../app/modules/surveys/api'

export function AsideMenuMain() {
  const [model, setModel] = useState({} as any)

  const intl = useIntl()
  const [menu, setMenu] = useState([] as any);

  
  useEffect(() => {
    get_data('all_survey2?skip=0&top=20')
    .then(data => {
       
      
      setModel(data.data);
      clearSearchResults(data.data)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
   
 

   
  }, [])

  const clearSearchResults = (new_data:any) => {
   console.log('sam');
    const token=localStorage.getItem('navItems12'); 
    // 
    if(!token)
    {
      let s; 
      let s7: { name: any; url: string; icon: string; children?: any[] }[]=[]; 
      let s77: { name: any; url: string; icon: string; children?: any[] }[]=[]; 
      
      // let s7=[]; 
      // s7.push({name:'الرئيسية',url:'/dashsam/',icon:'icon-home'})
   
  let all_data_sub=new_data.data;
// 
  let data =new_data.role;
        // this.data = this.all_data_sub.filter(a=>a.ParentId==0);

   data.forEach((a_main: { id: string; typeapp: string; title: any; name: string; url: string })=> {
  
    // s.push({name:a_main.Title,url:'/surveys/dashboard/'+a_main.id,icon:'icon-puzzle'});
  
     let data_sub =all_data_sub.filter((a: { role_id: string; parentid: null })=>a.role_id==a_main.id&&a.parentid==null);
      
      let children=[];
      let children7: { name: any; url: string; icon: string; children?: { name: any; url: string; icon: string; children?: any[] | undefined }[] }[]=[];
      if(data_sub)
    {
      // children.push({name:a_main.Title,url:'/project/'+a_main.id,icon:'icon-puzzle'})
      
    data_sub.forEach((a: { title: any; url: string; id: string })=> {
  
        // children.push({name:a.title,url:'/project/'+a.id,icon:'icon-puzzle'})
        if(a_main.typeapp=='pages')
        {
          children7.push({name:a.title,url:'/'+a.url,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='series7')
        {
          let s777: { name: any; url: string; icon: string; children?: any[] }[]=[]; 
      
        
       
          children7.push({name:a.title,url:'/surveys/series/'+a.id,icon:'icon-puzzle'})
          children7.push({name:a.title,url:'/surveys/dashboard/'+a.id,icon:'icon-puzzle'})
          children7.push({name:a.title,url:'/surveys/series/'+a.id,icon:'icon-puzzle', children:s777})

        } else if(a_main.typeapp=='report')
        {
        
          children7.push({name:a.title,url:'/surveys/report/'+a.id,icon:'icon-puzzle'})

        }  else if(a_main.typeapp=='series')
        {
        
          children7.push({name:a.title,url:'/surveys/series/'+a.id,icon:'icon-puzzle'})

        } else if(a_main.typeapp=='workflow')
        {
        
          children7.push({name:a.title,url:'/surveys/series/'+a.id,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='series_report')
        {
          
          children7.push({name:a.title,url:'/surveys/series_report/'+a.id,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='static_page')
        {
          
          children7.push({name:a.title,url:'/surveys/static_page/'+a.id,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='static_page2')
        {
          
          children7.push({name:a.title,url:'/surveys/static_page2/'+a.id,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='series_share')
        {
          
          children7.push({name:a.title,url:'/surveys/series_share/'+a.id,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='custom_page')
        {
          
          children7.push({name:a.title,url:'/surveys/custom_page/'+a.id,icon:'icon-puzzle'})

        }else if(a_main.typeapp=='charts')
        {
          
          children7.push({name:a.title,url:'/surveys/listFinger/'+a.id,icon:'icon-puzzle'})

        }else 
        
        {
          children7.push({name:a.title,url:'/surveys/dashboard/'+a.id,icon:'icon-puzzle'})
        }
        })
        // if(children.length>0)
        // s7.push({name:a_main.title,url:'/'+a_main.name,icon:'icon-puzzle', children:children});
        if(children7.length>0)
        s77.push({name:a_main.title,url:'/'+a_main.name,icon:'icon-puzzle', children:children7});
   
    }
    else
    {
      // s7.push({name:a_main.title,url:'/project/'+a_main.id,icon:'icon-puzzle'});
      if(a_main.typeapp=='pages')
      {
      s77.push({name:a_main.title,url:'/'+a_main.url,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='series')
      {
        s77.push({name:a_main.title,url:'/surveys/series/'+a_main.id,icon:'icon-puzzle'});

      } else if(a_main.typeapp=='report')
      {
      
     
        s77.push({name:a_main.title,url:'/surveys/report/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='workflow')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/series/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='series_report')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/series_report/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='static_page')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/static_page/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='static_page2')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/static_page2/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='series_share')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/series_share/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='custom_page')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/custom_page/'+a_main.id,icon:'icon-puzzle'});

      }else if(a_main.typeapp=='charts')
      {
        
     
        s77.push({name:a_main.title,url:'/surveys/listFinger/'+a_main.id,icon:'icon-puzzle'});

      }else
      {
      s77.push({name:a_main.title,url:'/surveys/dashboard/'+a_main.id,icon:'icon-puzzle'});

      }
    
    }
   
  })
  s7=s7.concat(s77);
  
  setMenu(s7)
  // this.navItems=s7.concat(s);
  // localStorage.setItem('navItems', JSON.stringify(this.navItems));
  
   
 
  
   
    }else
    {
      // this.navItems=JSON.parse(token);
    }
  
  };
  return (
    <>
      <AsideMenuItem
        to=''
        icon='/media/icons/duotune/art/art002.svg'
        title='الرئيسية'
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>العمليات</span>
        </div>
      </div>
      
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}

      
      {menu&&menu.map((plan:any, index:number) => {
                      return (
                        <AsideMenuItemWithSub key={index}
                        to={'/crafted'+plan.url}
                        title={plan.name}
                        icon='/media/icons/duotune/general/gen025.svg'
                        fontIcon='bi-layers'
                      >
                        
                        {plan.children&&plan.children.map((plan_2:any, index_2:number) => {
                      return (
                     
                         <AsideMenuItem  key={index_2} to={'/crafted'+plan_2.url} title={plan_2.name} hasBullet={true} />

                        );
                             
                      } ) } 
                      
                      </AsideMenuItemWithSub>
                        );
                      })} 

      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      
      <AsideMenuItem to='/crafted/surveys/tree/101950' title='tree' hasBullet={true} />
      
      {/* <AsideMenuItem to='/crafted/surveys/currencies' title='currencies' hasBullet={true} /> */}
      {/* <AsideMenuItem to='/crafted/surveys/grievances' title='grievances' hasBullet={true} /> */}
      {/* <AsideMenuItem to='/crafted/surveys/manage_bills_bills' title='manage_bills' hasBullet={true} /> */}

      
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}


     
 

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
    
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
